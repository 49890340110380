/*  ==========================================================================
    ==========================================================================

    Forms
    1. Standard Fields

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
    1. Standard Fields
    ========================================================================== */

/*  Fieldset
    ========================================================================== */

    fieldset {
        background: lighten($base-border-color, 10);
        border: $base-border;
        margin: 0 0 ($base-spacing / 2) 0;
        padding: $base-spacing;
    }

/*  Common 
    ========================================================================== */

    input,
    label,
    select {
        display: block;
        @extend .sans-regular;
        @include font-size($body-font-size);
    }

/*  Label
    ========================================================================== */

    label {
        font-weight: bold;
        margin-bottom: $base-spacing / 4;

        &.required:after {
            content: "*";
        }

        abbr {
            display: none;
        }
    }

/*  Textarea / Select
    ========================================================================== */

    textarea,
    #{$all-text-inputs},
    select[multiple=multiple] {
        @include transition(border-color);
        background-color: white;
        border-radius: $form-border-radius;
        border: 1px solid $form-border-color;
        box-shadow: $form-box-shadow;
        @extend .sans-regular;
        @include font-size($body-font-size);
        margin-bottom: $base-spacing / 2;
        padding: ($base-spacing / 3) ($base-spacing / 3);
        width: 100%;

        &:hover {
            border-color: $form-border-color-hover;
        }

        &:focus {
            border-color: $form-border-color-focus;
            box-shadow: $form-box-shadow-focus;
            outline: none;
        }
    }

/*  Textarea
    ========================================================================== */

    textarea {
        resize: vertical;
    }

/*  Search
    ========================================================================== */

    input[type="search"] {
        @include appearance(none);
    }

/*  Checkbox / Radio
    ========================================================================== */

    input[type="checkbox"],
    input[type="radio"] {
        display: inline;
        margin-right: $base-spacing / 4;
    }

/*  File Select
    ========================================================================== */

    input[type="file"] {
        padding-bottom: $base-spacing / 2;
        width: 100%;
    }

/*  Select
    ========================================================================== */

    select {
        margin-bottom: $base-spacing;
        max-width: 100%;
        width: auto;
    }
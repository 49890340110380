/*  ==========================================================================
    ==========================================================================

    Footer
    1. 

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. 
    ========================================================================== */
    .footer {
        background-color: $color-dark-ui-3;
        color: $white;
        padding: 1.5vh 5%;
    }
    .footer-sub-page {
        margin-top: 2.5vh;
    }
    .footer-wrapper {
        @extend .content;
    }
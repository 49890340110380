.documents {
    padding: 2.5vh 5% 0;
    height: calc(100%);
}
.documents-wrapper {
    @extend .content;
}
.documents-list {
    list-style-image: url('../images/document.png');
    padding: 0px 20px;
    
}
.document-list-item {
    margin: 10px 5px;
    position: relative;
    padding: 5px 0px;
    height: calc(100%);
    min-height: 35px;

    @include breakpoint($mobile-breakpoint) {
        min-height: 50px;
    }
}
.document-list-item a {
    position: absolute;
    top: 5px;
    line-height: 18px;
}
.sub-header--wrapper {
    @extend .content;
}
.sub-header {
    background-color: $color-light-ui-1;
    padding: 3vh 5%;
}
.sub-header--title {
    margin: 0px;
    color: $color-dark-ui-5;
    @include font-size($font-size-xl);
}
.sub-header--bread-crumbs {
    margin-top: 10px;
    @extend .sans-regular;
    @include font-size($font-size-r);
    color: $color-text-2;
    a {
        color: $color-text-2;
        &:hover {
            color: $color-text-1;
        }
    }
}
/*  ==========================================================================
    ==========================================================================

    Header
    1. 

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. 
    ========================================================================== */
    .header {}
    .header-container {
        padding: 5vh 5%;
    }
    .header-wrapper {
        display: flex;
        justify-content: space-between;
         
        @extend .content;
    }
    .header-tagline {

    }
    .tagline--primary {
        color: $color-text-4;
        margin: 0px;
        padding: 0px;

        @include font-size($h1-font-size-xl);
        @include breakpoint($mobile-breakpoint) {
            @include font-size($font-size-m);
        }
    }
    .tagline--secondary {
        color: $color-brand-2;
        margin: 0px;
        padding: 0px;

        @include font-size($h1-font-size-xl);
        @include breakpoint($mobile-breakpoint) {
            @include font-size($font-size-m);    
        }
    }
    .header-logo {
        min-width: 100px;
    }
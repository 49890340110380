/*  ==========================================================================
    ==========================================================================

    Home
    1. 

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. 
    ========================================================================== */
    .homepage-hero {
        height: 100%;
    }
    .homepage-hero--image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .homepage-content {
        @extend .content;
        min-height: 30vh;
    }
    body.blog {
        display: flex;
        justify-content: space-between;
    }

    .homepage {
        flex-grow: 1;
    }

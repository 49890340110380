/*  ==========================================================================
    ==========================================================================

    Navigation
    1. Main Nav

    CSS FORMATTING
    1. Positioning
    2. Display & Box Model
    3. Text
    4. Other

    ==========================================================================
    ========================================================================== */

/*  ========================================================================== 
	1. Main Nav
    ========================================================================== */
    .nav-wrapper {
        background-color: $nav-primary-color;
        padding: 0 5%;
        @include breakpoint($mobile-breakpoint) {
            padding: 0;    
        }
        @extend .sans-bold;
    }
    .navigation {
        @extend .content;
        @include breakpoint($mobile-breakpoint) {
            max-width: 100%;
        }
    }
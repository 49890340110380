/* 
 * Custom Skin Sample
 *
 * This file shows an example of a custom skin.  It targets any menu that has the Skin set to "None"
 *
 * Copy this to custom/custom.css to apply this custom skin
 *
 * For a copy of this file with comments for each style, see https://gist.github.com/sevenspark/24a087689db958670ed5
 */
/* UberMenu - Copyright 2011-2017, SevenSpark */
.ubermenu-skin-none {
    @include font-size($font-size-xs);
    color: #888888;
  }
  .ubermenu-skin-none .ubermenu-target {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-target-description {
    color: $white;
  }
  .ubermenu-skin-none.ubermenu-responsive-toggle {
    background: $nav-primary-color;
    color: $white;
    
    @include font-size($font-size-xs);
    
    text-transform: uppercase;
    font-weight: bold;
  }
  .ubermenu-skin-none.ubermenu-responsive-toggle:hover {
    background: $nav-hover-color;
    color: $white;
  }
  .ubermenu-skin-none {
    border: none;
    background: $nav-primary-color;
  }
  .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-item > .ubermenu-target,
  .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-parent > .ubermenu-target,
  .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-current-menu-ancestor > .ubermenu-target {
    color: $white;
    background: $nav-hover-color;
  }
  .ubermenu-skin-none .ubermenu-item.ubermenu-item-level-0 > .ubermenu-highlight {
    color: #4d90fe;
    background: transparent;
  }
  .ubermenu-skin-none.ubermenu-vertical .ubermenu-item-level-0 > .ubermenu-target {
    background: $nav-hover-color;
  }
  .ubermenu-skin-none .ubermenu-item-level-0 > .ubermenu-target {
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    border-left: none;
  }
  body:not(.rtl) .ubermenu-skin-none.ubermenu-horizontal .ubermenu-item-level-0:first-child > .ubermenu-target {
    box-shadow: none;
  }
  .ubermenu-skin-none .ubermenu-item-level-0:hover > .ubermenu-target,
  .ubermenu-skin-none .ubermenu-item-level-0.ubermenu-active > .ubermenu-target {
    background: $nav-hover-color;
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-item-level-0 > .ubermenu-submenu-type-stack > .ubermenu-item > .ubermenu-target:hover {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-submenu.ubermenu-submenu-drop {
    background: $nav-primary-color;
    border: 0px;
  }
  .ubermenu-skin-none.ubermenu-horizontal .ubermenu-item-level-0 > .ubermenu-submenu-drop {
    border-top: none;
  }
  .ubermenu-skin-none .ubermenu-tab-content-panel {
    background: #f9f9f9;
  }
  .ubermenu-skin-none .ubermenu-submenu,
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-target {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-highlight {
    color: #4d90fe;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-target:hover,
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-active > .ubermenu-target {
    color: $white;
    background: $nav-sub-menu-hover-color;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-target > .ubermenu-target-description {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-active > .ubermenu-target > .ubermenu-target-description {
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-item-header > .ubermenu-target {
    font-weight: bold;
    color: $white;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-item-header.ubermenu-has-submenu-stack > .ubermenu-target {
    border-bottom: 1px solid #e9e9e9;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-item-header > .ubermenu-target:hover {
    color: $white;
    background: $nav-sub-menu-hover-color;
  }
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-current-menu-item > .ubermenu-target {
    color: white;
    background: $nav-sub-menu-hover-color;
  }
  .ubermenu-nojs.ubermenu-skin-none .ubermenu-submenu .ubermenu-tabs .ubermenu-tab:hover > .ubermenu-target,
  .ubermenu-skin-none .ubermenu-submenu .ubermenu-tab.ubermenu-active > .ubermenu-target {
    background: #eeeeee;
    color: #111111;
  }
  .ubermenu-skin-none .ubermenu-tabs-group,
  .ubermenu-skin-none .ubermenu-tab > .ubermenu-target,
  .ubermenu-skin-none .ubermenu-tab-content-panel {
    border-color: #eeeeee;
  }
  .ubermenu-skin-none .ubermenu-submenu-drop {
    border-bottom-width: 3px;
  }
  .ubermenu .ubermenu-item-type-column>.ubermenu-submenu-type-stack>.ubermenu-item-normal:first-child {
      margin-top: 0px;
  }
  .ubermenu .ubermenu-item-type-column>.ubermenu-submenu-type-stack>.ubermenu-item-normal:last-child {
    margin-bottom: 0px;
  }
  .ubermenu .ubermenu-autocolumn, .ubermenu .ubermenu-submenu-type-stack {
      padding-top: 0px;
      padding-bottom: 0px;
  }
  .ubermenu .ubermenu-submenu-type-stack>.ubermenu-item-normal>.ubermenu-target {
      padding-top: 15px;
      padding-bottom: 15px;
  }

  .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column, .ubermenu-responsive-default.ubermenu.ubermenu-responsive .ubermenu-column-auto {
    @media screen and (max-width: 959px) {
      min-width: 100%;
    }
  }
  .ubermenu-responsive-toggle, .ubermenu .ubermenu-colgroup .ubermenu-column, .ubermenu .ubermenu-custom-content-padded, .ubermenu .ubermenu-nonlink, .ubermenu .ubermenu-retractor, .ubermenu .ubermenu-submenu-padded, .ubermenu .ubermenu-submenu-type-stack>.ubermenu-item-normal>.ubermenu-target, .ubermenu .ubermenu-target, .ubermenu .ubermenu-widget, .ubermenu-responsive-toggle {
    @include breakpoint($mobile-breakpoint) {
      padding: 15px 5%;
    }
  }